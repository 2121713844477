<template>
    <v-layout align-center justify-center>
        <v-flex mx12 sm8 lg5 x14>
            <v-card>
                <v-toolbar dark color="#802523">
                <v-toolbar-title>
                    Accés a l'Intranet:
                </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-text-field v-model="username" autofocus color="accent" label="Usuari" required>
                    </v-text-field>
                    <v-text-field v-model="password" type="password" color="accent" label="Contrasenya" required>
                    </v-text-field>
                    <v-flex class="red--text" v-if="error">
                        {{error}}
                    </v-flex>
                </v-card-text>
                <v-card-actions class="px-3 pb-3">
                    <v-flex text-xs-right>
                        <v-btn  @click="ingresar()" color="primary">Accedir</v-btn>
                    </v-flex>                    
                </v-card-actions>
                
            </v-card>
        </v-flex>            
    </v-layout>
</template>
<script>
import axios from 'axios';
export default {
    
    data(){
        return{
            username:'',
            password:'',
            error:null
        }
    },
    methods:{
        ingresar(){
            axios.post('/login',{'username':this.username, 'password':this.password})
            .then((response)=>{
                return response.data
            })
            .then((data)=>{
                this.$store.dispatch("guardaToken", data.tokenReturn);
                this.$router.push({name:'Home'})
            })
            .catch((error)=>{
                //console.log(error)
                this.error=null;
                if(error.response.status==404){
                    this.error='No existeix el usuari o les credencials són incorrectes';
                }else{
                    this.error='Hi ha un error amb el servidor';
                }
            });
        }
    }
}
</script>
