<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo_centrat_color.png')"
          class="my-3"
          contain
          height="200"
        />
      </v-col>

      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          <span>Benvigut  {{first_name}} !</span>
        </h1>

        <p class="subheading font-weight-regular">
          Des d'aquest area els treballadors podreu fer els vostres marcatges d'entrada i sortida. per poder fer-ho abans de tot caldra que us autentifiqueu amb el vostre usuari i contrasenya de l'Intranet.
          
          <template>
            <div v-if="registrat">              
            </div>
            <div v-else>
              <br>Ho podeu fer clicant al enllaç seguent, o des del menu superior dret, en entrar.<br><br>
              <v-btn :to="{name:'Login'}">Entrar</v-btn>
            </div>            
          </template>
        </p>
        <h2 class="headline font-weight-bold mb-3">
          Que és el següent?
        </h2>
      </v-col>
    </v-row>
    <v-row jusitfy="start" v-if="registrat">
      <v-col>           
           <v-card
              class="mx-auto"
              max-width="344"
              outlined
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-4">
                    Entrada
                  </div>
                  <v-list-item-title class="text-h5 mb-1">
                    {{timeDisplay}}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{dayDisplay}}</v-list-item-subtitle>
                </v-list-item-content>
          
                <v-list-item-avatar
                  tile
                  size="80"
                  
                >
                <v-icon
                  large
                  color="green darken-2"
                  >
                  mdi-domain
                </v-icon>
                </v-list-item-avatar>
              </v-list-item>
          
              <v-card-actions>
                <v-btn
                :disabled="btn_e"
                  outlined
                  rounded
                  text
                  @click="entrada()"
                >
                  Fitxar
                </v-btn>
              </v-card-actions>
            </v-card>
      </v-col>
      <v-col justify="start">                                
          <v-card
      class="mx-auto"
      max-width="344"
      outlined
    >
      <v-list-item three-line>
        <v-list-item-content>
          <div class="text-overline mb-4">
            Sortida
          </div>
          <v-list-item-title class="text-h5 mb-1">
            {{timeDisplay}}
          </v-list-item-title>
          <v-list-item-subtitle>{{dayDisplay}}</v-list-item-subtitle>
        </v-list-item-content>
  
        <v-list-item-avatar
          tile
          size="80"
          
        >
          <v-icon
            large
            color="red darken-2"
          >
            mdi-home
          </v-icon>
        </v-list-item-avatar>
      </v-list-item>
  
      <v-card-actions>
        <v-btn 
          :disabled="btn_s"
          outlined
          rounded
          text @click="sortida()"
        >
          Fitxar
        </v-btn>
      </v-card-actions>
    </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-alert v-show="valida" :type="style">
          {{message}}
          </v-alert>  
        </v-col>
      </v-row>    
      <v-row>
        <v-col>
          <h2 class="headline font-weight-bold mb-3">
            Enllaços d'interès:
          </h2>        
          <a
            v-for="(link, i) in importantLinks"
            :key="i"
            :href="link.href"
            class="subheading mx-3"
            target="_blank"
          >
            {{ link.text }}
          </a>
        </v-col>
      </v-row>      
  </v-container>
</template>
<script>
  import Login from './Login.vue'
  import axios from 'axios'
  export default {
  components: { Login },
    name: 'HelloWorld',

    data(){ return {      
      importantLinks: [
          {
            text: 'Web Municipal',
            href: 'https://www.svc.cat',
          },        
        ],
        first_name:"",
        timeDisplay:"",
        dayDisplay:"",
        message:"",
        valida:"",
        style:"success",
        btn_e:false,
        btn_s:false
      }
    },
    computed:{
      registrat(){      
      return this.$store.state.usuari;
      }, 
    },
    created(){
      if(this.$store.state.usuari){
        this.first_name = this.$store.state.usuari.first_name
      }
      setInterval(this.refreshTime, 1000);
      var d = new Date(); 
		  this.dayDisplay = d.getFullYear()+'-'+(d.getMonth()+1)+'-'+d.getDate(); 
    },
    methods:{
      refreshTime(){
        var dateString = new Date();
        var formattedString = dateString.getHours() + ":" + dateString.getMinutes() + ":" + dateString.getSeconds();
        this.timeDisplay = formattedString;
    },
    entrada(){
      var me = this;
      axios.post('/entrada',{'entrada_intr':1,'id_crosschex':this.$store.state.usuari.id_crosschex, 'pass_crosschex':this.$store.state.usuari.pass_crosschex})
            .then((response)=>{
                return response.data.data                
            })
            .then((data)=>{
                me.valida=1;
                me.message=(data.msg);
                me.style="success";  
                me.btn_e= true;              
            })
            .catch((error)=>{
                //console.log(error)
                me.valida=1
                me.message=error;
                me.style="error";
            });
    },
    sortida(){
      var me = this;
      axios.post('/sortida',{'sortida_intr':1,'id_crosschex':this.$store.state.usuari.id_crosschex, 'pass_crosschex':this.$store.state.usuari.pass_crosschex})
            .then((response)=>{
              
                return response.data.data
            })
            .then((data)=>{
                me.valida=1;
                me.message=(data.msg);
                me.style="success";
                me.btn_s= true;                
            })
            .catch((error)=>{
                
                me.valida=1
                me.message=error;
                me.style="error";
            });
    }

    
  },
}
</script>
