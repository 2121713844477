<template>
  <v-app id="app">
  <v-navigation-drawer
      v-model="drawer" v-if="registrat"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
    >
      <v-list dense>
        <template v-if="registrat">
        <v-list-item :to="{name:'Home'}">
          <v-list-item-action>
            <v-icon>mdi-home</v-icon>
          </v-list-item-action>
          <v-list-item-title>
            Inici
          </v-list-item-title>
        </v-list-item>
        </template> 
        <template v-if="registrat">
          <v-list-group>
            <v-list-item slot="activator">
              <v-list-item-content>
                <v-list-item-title>
                  El meu espai
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item :to="{name: 'Home'}">
            <v-list-item-action>
              <v-icon>mdi-forward</v-icon>
            </v-list-item-action>              
              <v-list-item-content>
                <v-list-item-title>
                  Fer Marcatge
                </v-list-item-title>
              </v-list-item-content>            
            </v-list-item>       
          </v-list-group>
        </template>

      </v-list>
      </v-navigation-drawer>
    
        <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="#802523"
      dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title
        style="width: 300px"
        class="ml-0 pl-4"
      >
        <span class="hidden-sm-and-down">Accions</span>
        
      
      </v-toolbar-title>      
      <v-spacer></v-spacer>
      <v-btn mr-2 v-if="registrat" icon @click="logout()">        
        Sortir <v-icon>mdi-logout</v-icon>
      </v-btn>
      <v-btn mr-2 :to="{name:'Login'}" v-else icon>
        Entrar <v-icon>mdi-apps</v-icon>

      </v-btn>
    </v-app-bar>
    <v-main>
      <v-container
        class="fill-height"
        fluid
      >
      <v-slide-y-transition mode="out-in">
        <router-view></router-view>
      </v-slide-y-transition>
      </v-container>
    </v-main>
    <v-footer height="auto" color="#eeeeee">
      <v-layout justify-center>
        <v-flex text-xs-center>
          <v-card flat title color="#eeeeee" class="white--text">
            <v-card-text class="black--text pt-0" align="center">            
            <v-img
            :src="require('./assets/logo_footer.png')"
            
            contain
            height="50"
            />            
            © 2021 Copyright: Ajutament de Sant Vicenç de Castellet &copy;2021
          </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-footer>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  data(){
    return{
      drawer:true
    }
  },
  computed:{
    registrat(){
      return this.$store.state.usuari
    },   
  },
  created(){
    this.$store.dispatch('autoLogin');
  },
  methods:{
    logout(){
      this.$store.dispatch('sortir');
    }

  }
};
</script>
